var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
var dirty = ref.dirty;
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"disabled":_vm.submitting},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleFormSubmit)}}},[_c('v-card',{attrs:{"loading":_vm.loading || _vm.submitting}},[_c('v-card-title',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',{staticClass:"pt-0"},[_vm._l((_vm.tableConfig),function(ref){
var label = ref.label;
var dataType = ref.dataType;
var name = ref.name;
var rules = ref.rules;
var multiple = ref.multiple;
var suffix = ref.suffix;
var elementType = ref.elementType;
return _c('div',{key:name},[_c('ValidationProvider',{attrs:{"name":label,"rules":rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!elementType || elementType === 'text')?_c('v-text-field',{attrs:{"data-vv-name":name,"error-messages":errors,"type":dataType || 'string',"label":label,"suffix":suffix},model:{value:(_vm.tableData[name]),callback:function ($$v) {_vm.$set(_vm.tableData, name, $$v)},expression:"tableData[name]"}}):_vm._e(),(elementType === 'select' && _vm.tableConfigCategories)?_c('v-select',{staticClass:"pt-1 pb-0",attrs:{"data-vv-name":name,"single-line":"","multiple":multiple,"label":label,"item-value":"_id","item-text":"name","items":_vm.tableConfigCategories[name]},model:{value:(_vm.tableData[name]),callback:function ($$v) {_vm.$set(_vm.tableData, name, $$v)},expression:"tableData[name]"}}):_vm._e()]}}],null,true)})],1)}),_c('v-row',[_c('FormErrors',{attrs:{"vee-errors":errors,"other-errors":_vm.customErrors}})],1)],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mt-4",on:{"click":_vm.cancel}},[_vm._v(" Discard ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"shamrock white--text","type":"submit","disabled":invalid || !dirty}},[_vm._v(" "+_vm._s(_vm.tableData._id ? 'Save' : 'Create')+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }