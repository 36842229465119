<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit, errors, dirty, invalid }"
  >
    <v-form
      :disabled="submitting"
      @submit.prevent="handleSubmit(handleFormSubmit)"
    >
      <v-card :loading="loading || submitting">
        <v-card-title class="title">
          {{ title }}
        </v-card-title>
        <v-card-text class="pt-0">
          <div
            v-for="({label, dataType, name, rules, multiple, suffix, elementType}) in tableConfig"
            :key="name"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :name="label"
              :rules="rules"
            >
              <v-text-field
                v-if="!elementType || elementType === 'text'"
                v-model="tableData[name]"
                :data-vv-name="name"
                :error-messages="errors"
                :type="dataType || 'string'"
                :label="label"
                :suffix="suffix"
              />
              <v-select
                v-if="elementType === 'select' && tableConfigCategories"
                v-model="tableData[name]"
                :data-vv-name="name"
                class="pt-1 pb-0"
                single-line
                :multiple="multiple"
                :label="label"
                item-value="_id"
                item-text="name"
                :items="tableConfigCategories[name]"
              />
            </ValidationProvider>
          </div>
          <v-row>
            <FormErrors
              :vee-errors="errors"
              :other-errors="customErrors"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="mt-4"
            @click="cancel"
          >
            Discard
          </v-btn>
          <v-btn
            class="mt-4"
            color="shamrock white--text"
            type="submit"
            :disabled="invalid || !dirty"
          >
            {{ tableData._id ? 'Save' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>

import FormErrors from '@/components/form/FormErrors'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'SimpleTableForm',
  components: { FormErrors, ValidationObserver, ValidationProvider },
  namespaced: true,
  props: {
    id: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    tableConfigCategories: {
      type: Object,
      default: () => {}
    },
    tableConfig: {
      type: Array,
      required: true
    },
    serverType: {
      type: String,
      required: true
    },
    returnRoute: {
      type: String,
      required: true
    }
  },
  data () {
    const tableConfigDefaults = this.defaultTableData()
    return {
      customErrors: [],
      submitting: false,
      tableData: {
        _id: null,
        ...tableConfigDefaults
      }
    }
  },
  async created () {
    if (this.id) {
      const { data: serverData } = await this.$axios.get(`/${this.serverType}/${this.id}`)
      this.tableData = serverData
    }
  },
  methods: {
    async cancel () {
      if (this.tableData._id) {
        await this.$router.push(this.returnRoute)
      } else {
        this.reset()
        this.$emit('created')
      }
    },
    defaultTableData () {
      return this.tableConfig.reduce((acc, value) => {
        acc[value.name] = null
        return acc
      }, {})
    },
    async createElement () {
      try {
        await this.$axios.post(`/${this.serverType}`, { ...this.tableData })
        this.reset()
        this.$emit('created')
      } catch (err) {
        if (err.response?.status === 400) {
          await this.$store.dispatch('showSnackbar', { color: 'error', text: err.response.data.message })
        }
        throw err
      }
    },
    async updateElement () {
      try {
        this.submitting = true
        await this.$axios.put(`/${this.serverType}/${this.tableData._id}`, {
          ...this.tableData,
          defaultValue: this.tableData.value
        })
        this.$emit('updated')
      } catch (err) {
        if (err.response?.status === 400) {
          await this.$store.dispatch('showSnackbar', { color: 'error', text: err.response.data.message })
        }
        throw err
      } finally {
        this.submitting = false
      }
    },
    reset () {
      const tableConfigDefaults = this.defaultTableData()
      this.tableData = {
        _id: null,
        ...tableConfigDefaults
      }
      this.$refs.observer.reset()
    },
    async handleFormSubmit () {
      this.submitting = true
      this.customErrors = []
      try {
        if (this.tableData._id) {
          await this.updateElement()
          await this.$router.push(this.returnRoute)
        } else {
          await this.createElement()
        }
      } catch (err) {
        this.customErrors.push(err.response?.data?.message ?? err.message)
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
